export enum FileType {
  IMG = 'IMG',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOC = 'DOC',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  CAROUSEL = 'CAROUSEL',

  ORGANISATION_LOGO = 'ORGANISATION_LOGO',
  ORGANISATION_VIDEO = 'ORGANISATION_VIDEO',
  ORGANISATION_IMAGE = 'ORGANISATION_IMAGE',
  ORGANISATION_LEADER_PICTURE = 'ORGANISATION_LEADER_PICTURE',
  ORGANISATION_TEAM_MEDIA_VIDEO = 'ORGANISATION_TEAM_MEDIA_VIDEO',
  ORGANISATION_TEAM_MEDIA_THUMBNAIL_VIDEO = 'ORGANISATION_TEAM_MEDIA_THUMBNAIL_VIDEO',
  ORGANISATION_TEAM_MEDIA_PICTURE = 'ORGANISATION_TEAM_MEDIA_PICTURE',
  EXECUTIVE_TEAM_PICTURE = 'EXECUTIVE_TEAM_PICTURE',
  BOARD_MEMBER_PICTURE = 'BOARD_MEMBER_PICTURE',
  ORGANISATION_CHAMPION_VIDEO = 'ORGANISATION_CHAMPION_VIDEO',
  ORGANISATION_CHAMPION_THUMBNAIL_VIDEO = 'ORGANISATION_CHAMPION_THUMBNAIL_VIDEO',
  ORGANISATION_CHAMPION_DILIGENCE_REPORT = 'ORGANISATION_CHAMPION_DILIGENCE_REPORT',
  ORGANISATION_DOCUMENT = 'ORGANISATION_DOCUMENT',

  PROJECT_VIDEO = 'PROJECT_VIDEO',
  PROJECT_IMAGE = 'PROJECT_IMAGE',
  PROJECT_TEAM_MEDIA_VIDEO = 'PROJECT_TEAM_MEDIA_VIDEO',
  PROJECT_TEAM_MEDIA_THUMBNAIL_VIDEO = 'PROJECT_TEAM_MEDIA_THUMBNAIL_VIDEO',
  PROJECT_TEAM_MEDIA_PICTURE = 'PROJECT_TEAM_MEDIA_PICTURE',
  PROJECT_TEAM_MEMBER = 'PROJECT_TEAM_MEMBER',
  PROJECT_CHAMPION_VIDEO = 'PROJECT_CHAMPION_VIDEO',
  PROJECT_CHAMPION_THUMBNAIL_VIDEO = 'PROJECT_CHAMPION_THUMBNAIL_VIDEO',
  PROJECT_CHAMPION_DILIGENCE_REPORT = 'PROJECT_CHAMPION_DILIGENCE_REPORT',
  PROJECT_UPDATE_PICTURE = 'PROJECT_UPDATE_PICTURE',
  PROJECT_UPDATE_VIDEO = 'PROJECT_UPDATE_VIDEO',
  PROJECT_UPDATE_VIDEO_THUMBNAIL = 'PROJECT_UPDATE_THUMBNAIL_VIDEO',
  PROJECT_DOCUMENT = 'PROJECT_DOCUMENT',

  GROUP_VISION_VIDEO = 'GROUP_VISION_VIDEO',
  GROUP_VISION_IMAGE = 'GROUP_VISION_IMAGE',
  GROUP_VISION_THUMBNAIL_VIDEO = 'GROUP_VISION_THUMBNAIL_VIDEO',
  GROUP_USEFUL_DOCUMENT = 'GROUP_USEFUL_DOCUMENT',
  ANNOUNCEMENT_DOCUMENT = 'ANNOUNCEMENT_DOCUMENT',
  ANNOUNCEMENT_COVER_IMAGE = 'ANNOUNCEMENT_COVER_IMAGE',

  PORTFOLIO_IMAGE = 'PORTFOLIO_IMAGE',
  PORTFOLIO_VIDEO = 'PORTFOLIO_VIDEO',
  PORTFOLIO_THUMBNAIL_VIDEO = 'PORTFOLIO_THUMBNAIL_VIDEO',
  PORTFOLIO_CHAMPION_VIDEO = 'PORTFOLIO_CHAMPION_VIDEO',
  PORTFOLIO_CHAMPION_DILIGENCE_REPORT = 'PORTFOLIO_CHAMPION_DILIGENCE_REPORT',

  APPLICATION_LOGO = 'APPLICATION_LOGO',
  GENERAL_VIDEO = 'GENERAL_VIDEO',
  GENERAL_IMAGE = 'GENERAL_IMAGE',
  CHAMPION_HIGH_QUALITY_IMAGE = 'CHAMPION_HIGH_QUALITY_IMAGE',
  CHAMPION_ENTITY_VIDEO = 'CHAMPION_ENTITY_VIDEO',
  CHAMPION_ENTITY_DOCUMENT = 'CHAMPION_ENTITY_DOCUMENT',

  BADGE_IMAGE = 'BADGE_IMAGE',
  GROUP_REPORT_ATTACHMENT = 'GROUP_REPORT_ATTACHMENT',

  FUNDER_LOGO = 'FUNDER_LOGO',
  FUNDER_VIDEO = 'FUNDER_VIDEO',
  FUNDER_IMAGE = 'FUNDER_IMAGE',
  FUNDER_LEADER_PICTURE = 'FUNDER_LEADER_PICTURE',
  FUNDER_DOCUMENT = 'FUNDER_DOCUMENT',
  GRANTMAKER_PICTURE = 'GRANTMAKER_PICTURE',
}

export const DOC_FORMATS = ['txt', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
export const IMG_FORMATS = ['jpg', 'jpeg', 'bmp', 'png', 'jfif', 'webp'];
export const VIDEO_FORMATS = ['mp4', 'mov', 'avi', 'mpeg', 'ogv', 'webm', '3gp'];
export const DOC_MIME_TYPES = [
  'text/plain',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];
export const IMG_MIME_TYPES = ['image/jpeg', 'image/bmp', 'image/png', 'image/webp'];
export const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/avi',
  'video/quicktime',
  'video/mpeg',
  'video/ogg',
  'video/x-msvideo',
  'video/mp2t',
  'video/webm',
  'video/3gpp',
  'video/3gpp2',
];
export const investorPhotosExampleArray = [
  'https://fdi-files.s3.amazonaws.com/public/IMG_2093232332.jpeg',
  'https://fdi-files.s3.amazonaws.com/public/IMG_20180105_151443.jpg',
  'https://fdi-files.s3.amazonaws.com/public/IMG_20180723_183422.jpg',
  'https://fdi-files.s3.amazonaws.com/public/image.jpg',
];
