import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterString',
})
export class FilterStringPipe implements PipeTransform {
  transform(value: any[], field: string, str: any): unknown {
    if (!value || !field || !str) return value;
    const filterFn = (text: string) => text.toLowerCase().includes(str.toLowerCase()),
      predicate = field ? (item) => filterFn(item[field]) : (item) => filterFn(item);
    return value.length ? value.filter(predicate) : [];
  }
}
