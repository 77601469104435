import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { AuthService } from '../api/auth.service';
import StorageHelper from '../helpers/Storage.helper';
import { TokenData } from '../models/Auth';
import { LoaderService } from '../services/loader.service';
import { catchError, concatMap, exhaustMap, first, tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { ToastrMessages } from '../constants/ToastrMessages';
import { environment } from '../../../environments/environment';
import { ToastService } from '../services/toast.service';
import { Router } from '@angular/router';
import Urls from '../constants/Urls';
import { GlobalLoaderRequests, LOADER_TYPE, NoLoaderRequests } from '../constants/Global/Loader';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: Subject<any> = new Subject<any>();

  // prettier-ignore
  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private injector: Injector,
    private toastService: ToastService,
    private router: Router
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const noLoaderRequest = !!NoLoaderRequests.find((reqName) => request.url.indexOf(reqName) >= 0);
    const globalLoaderRequest = !!GlobalLoaderRequests.find((reqName) => request.url.indexOf(reqName) >= 0);
    let loader: LOADER_TYPE;
    if (!noLoaderRequest) {
      loader = globalLoaderRequest ? LOADER_TYPE.GLOBAL : LOADER_TYPE.LOCAL;
      this.loaderService.show(loader);
    }

    return next.handle(this.setHeaders(request)).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loaderService.hide(loader);
        }
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          const throwErr = (): Observable<any> => {
            const err = error.error.message || error.error.error;
            return throwError(err);
          };
          this.loaderService.hide(loader);
          switch (error.status) {
            case 401:
              return this.handle401Error(request, next);
            case 502:
            case 0:
              this.router.navigate([Urls.MAINTENANCE], {
                queryParams: {
                  intercepted: true,
                },
                skipLocationChange: true,
              });
              return throwErr();
            default:
              return throwErr();
          }
        } else {
          return of(null);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!this.isRefreshing) {
      StorageHelper.clearToken();
      this.isRefreshing = true;
      return this.authService.refreshToken().pipe(
        exhaustMap((tokenData: TokenData) => {
          StorageHelper.saveToken(tokenData.access_token);
          this.refreshTokenSubject.next(tokenData.access_token);
          this.isRefreshing = false;
          return next.handle(this.setHeaders(request));
        }),
        catchError((error: any) => {
          this.injector.get(UserService).killSession(`${Urls.AUTH}/${Urls.LOGIN}?redirectUrl=${this.router.url}`);
          this.toastService.error(ToastrMessages.EXPIRED_SESSION);
          this.isRefreshing = false;
          return throwError(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        first(),
        concatMap(() => next.handle(this.setHeaders(request)))
      );
    }
  }

  private setHeaders(request: HttpRequest<any>) {
    let headers = request.headers
      .set('Access-Control-Allow-Origin', '*')
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('tenantId', environment.tenantId)
      .set('language', StorageHelper.getSelectedLanguage()?.code || 'eng');
    const token = StorageHelper.getToken();

    headers = token
      ? headers.set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`)
      : headers.set('Authorization', 'Basic ZGlyZWN0b3J5OnNlY3JldA==');

    if (headers.has('no-content-type')) {
      headers = headers.delete('content-type');
      headers = headers.delete('no-content-type');
    }

    return request.clone({ headers });
  }
}
