import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { FileType } from '../constants/FileType';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  private readonly resourceUrl: string = '/v1/general';

  // prettier-ignore
  constructor(
    private apiService: ApiService,
  ) {
  }

  getUploadPath() {
    return `${this.resourceUrl}/upload/file`;
  }

  getUploadUrl() {
    return `${environment.server}${this.getUploadPath()}`;
  }

  getDeletePath() {
    return `${this.resourceUrl}/delete/file`;
  }

  getDeleteUrl() {
    return `${environment.server}${this.getDeletePath()}`;
  }

  deleteFiles(entityUuid: string, fileType: FileType, fileUuid: string) {
    return this.apiService.delete(`${this.resourceUrl}/delete/file`, { entityUuid, fileType, fileUuid });
  }

  getDropdowns(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/dropdowns`);
  }

  saveGlobalKeyValue(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/settings/create-update`, body);
  }

  getGlobalSettings(): Observable<any> {
    return this.apiService.get(`/v2/general/settings`);
  }

  deleteGlobalSettingKey(key): Observable<any> {
    return this.apiService.delete(`${this.resourceUrl}/settings/${key}`);
  }

  saveHomepageFocusConfig(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/settings/carousel/create-update`, body);
  }

  saveCsvFile(body: FormData): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/upload/file`, body);
  }
}
