export enum CompanyOrFirmStatus {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export enum EntityStatus {
  LIVE = 'LIVE',
  DRAFT = 'DRAFT',
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  ARCHIVED = 'ARCHIVED',
  DECLINED = 'DECLINED',
  EXPIRED = 'EXPIRED',
}

export const ENTITY_STATUS_ACTIONS = [
  {
    status: EntityStatus.LIVE,
    action: 'MAKE_LIVE',
  },
  {
    status: EntityStatus.PENDING,
    action: 'MAKE_PENDING',
  },
  {
    status: EntityStatus.IDLE,
    action: 'MAKE_IDLE',
  },
  {
    status: EntityStatus.ARCHIVED,
    action: 'ARCHIVE',
  },
  {
    status: EntityStatus.DECLINED,
    action: 'DECLINE',
  },
];

export enum EntityType {
  ORGANIZATION = 'ORGANISATION',
  PORTFOLIO = 'PORTFOLIO',
}

export enum ProjectType {
  PROJECT = 'PROJECT',
  ORGANISATION = 'ORGANISATION',
  PORTFOLIO = 'PORTFOLIO',
  CHARITY_DIRECTORY = 'CHARITY_DIRECTORY',
  FUNDER = 'FUNDER',
}

export enum ENTITY_TYPE {
  PROJECT = 'PROJECT',
  ORGANISATION = 'ORGANISATION',
  GROUP = 'GROUP',
  PORTFOLIO = 'PORTFOLIO',
  FUNDER = 'FUNDER',
}

export const ProjectTypeVideos = [
  {
    title: ProjectType.CHARITY_DIRECTORY,
    videoSource: 'https://charities-files.s3.us-west-1.amazonaws.com/public/TrustBridge+Global+Charity+Directory.mp4',
  },
];

export enum Watchlist {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export enum CommentType {
  QUESTION = 'QUESTION',
  ANSWER = 'ANSWER',
}
