import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly apiUrl;

  // prettier-ignore
  constructor(
    private http: HttpClient,
  ) {
    this.apiUrl = environment.server;
  }

  get(path: string, params = {}, headers = {}, otherParams = {}) {
    return this.http.get(`${this.apiUrl}${path}`, { params, headers, ...otherParams });
  }

  put(path: string, body = {}, params = {}) {
    return this.http.put(`${this.apiUrl}${path}`, body, { params });
  }

  post(path: string, body = {}, params = {}, headers = {}, fields = {}) {
    return this.http.post(`${this.apiUrl}${path}`, body, { params, headers, ...fields });
  }

  delete(path: string, params = {}, body = {}) {
    return this.http.delete(`${this.apiUrl}${path}`, { params, body });
  }

  getApiUrl() {
    return this.apiUrl;
  }
}
