import { EntityStatus, ENTITY_TYPE } from '../constants/CompanyOrFirm';
import { PORTFOLIO_TYPE } from '../constants/portfolios/Portfolios';
import Urls from '../constants/Urls';
import { DocumentMedia } from './FormControls';
import { Language } from './global-settings/Language';
import { Media } from './Media';

export interface ResponseObject<T> {
  response: T;
}

export interface PaginationResponse {
  pageNumber: number;
  totalObjects: number;
  from: number;
  to: number;
}

export interface PaginatedResponse<T> extends PaginationResponse {
  objects: T[];
  invitedUserList?: any;
  pendingInvitationsCounter?: number;
  requestedToJoinCounter?: number;
}

export class UUIDName<T = string> {
  uuid: string;
  name: T;
  disabled: boolean;

  constructor(uuid: string, name: T) {
    this.uuid = uuid;
    this.name = name;
    this.disabled = false;
  }
}

export interface UUIDSymbol {
  uuid: string;
  symbol: string;
}

export type Enum = { [k: string]: string };

export class CodeValue {
  code: string;
  value: string | number | boolean;

  constructor(code: string, value?: string | number | boolean) {
    this.code = code;
    this.value = value !== undefined ? value : code;
  }
}

export interface GenericObject {
  uuid?: string;
  code?: string;
  text?: string;
  title?: string;
  value?: string | number;
  name?: string;
  visibility?: boolean;
  privileges?: object;
}

export interface ArrayObjects {
  [key: string]: string[];
}

export class NavigationData {
  url: string;
  queryParams: { [k: string]: string | boolean | number };

  constructor(url: string, queryParams?: { [k: string]: string | boolean | number }) {
    this.url = url;
    this.queryParams = queryParams;
  }
}

export interface EntityImage {
  mediaDto: Media;
  position: number;
}

export interface EntityDocument {
  documentDto: DocumentMedia;
  position: number;
}

export class EntityMainInfo {
  uuid: string;
  name: string;
  status: EntityStatus;
  language: Language;
  statusDisabled: boolean;
  showStatusDropdown: boolean;
  decliningReason: string;

  constructor(entityUuid: string, entityName: string, entityStatus: EntityStatus, reason?: string) {
    this.uuid = entityUuid;
    this.name = entityName;
    this.status = entityStatus;
    this.decliningReason = reason;
  }

  setEntityStatus(newStatus: EntityStatus): void {
    this.status = newStatus;
    if (this.language && this.language.hasOwnProperty('entityStatus')) {
      this.language.entityStatus = newStatus;
    }
    this.handleStatusButton();
  }

  handleStatusButton(): void {
    this.statusDisabled = this.status === EntityStatus.DRAFT || this.status === EntityStatus.EXPIRED || this.status === EntityStatus.DECLINED;
    this.showStatusDropdown = this.language?.primaryLanguage && this.status !== EntityStatus.ARCHIVED;
  }

  setSelectedLanguage(language: Language): void {
    this.language = language;
    if (this.language) this.handleStatusButton();
  }
}

export class CatalogEntityCard {
  name: string;
  uuid: string;
  favorite: boolean;
  amountRaised: number;
  currencySymbol: string;
  totalBudget: number;
  coverImageUrl: string;
  coverVideoUrl: string;
  description: string;
  languageCode: string;
  parentOrganisation: UUIDName;
  categories: string[];
  countries: string[];
  cause: string;
  manager: string;
  leader: string;
  detailsPageLink: string;
  parentOrganisationLink: string;
  multipleCountries: boolean;
  multipleCategories: boolean;
  isProject: boolean;
  isOrganisation: boolean;
  isPortfolio: boolean;
  numberOfProjects: number;
  numberOfOrganisations: number;
  type: PORTFOLIO_TYPE;
  allocation: number;
  entityType: ENTITY_TYPE;
  badges: CatalogEntityBadge[];
  projects: any[];
  entityLogoUrl: string;
  numberOfFunders: number;
  missionStatement: string;
  organisationWebsite: string = '';

  constructor(entityInfo: any) {
    this.isProject = !!entityInfo.projectUuid;
    this.isOrganisation = !this.isProject && !!entityInfo.organisationUuid;
    this.languageCode = entityInfo.languageCode;

    if (this.isProject) {
      this.setProjectFields(entityInfo);
    } else if (this.isOrganisation) {
      this.setOrganisationFields(entityInfo);
    } else {
      this.isPortfolio = true;
      this.setPortfolioFields(entityInfo);
      return;
    }

    this.amountRaised = entityInfo.amountRaisedToDate;
    this.currencySymbol = entityInfo.amountRaisedToDateCurrencySymbol;
    this.favorite = entityInfo.addedToWatchlist;
    this.totalBudget = entityInfo.totalProjectBudget;
  }

  setProjectFields(entityInfo): void {
    this.entityType = ENTITY_TYPE.PROJECT;
    this.name = entityInfo.projectName;
    this.uuid = entityInfo.projectUuid;
    this.coverImageUrl = entityInfo.coverImageUrl;
    this.coverVideoUrl = entityInfo.projectVideoUrl;
    this.parentOrganisation = new UUIDName(entityInfo.organisationUuid, entityInfo.organisationName);
    this.cause = entityInfo.projectCauseName;
    this.setCountries(entityInfo?.projectCountriesNames);
    this.categories = entityInfo.projectCategoriesNames;
    this.manager = entityInfo.projectManagers?.length && entityInfo.projectManagers[0];
    this.description = entityInfo.description;
    this.allocation = entityInfo.percent;
    this.detailsPageLink = `/${Urls.APP}/${Urls.DETAILS}/${Urls.PROJECT}/${this.uuid}/${this.languageCode}`;
    this.parentOrganisationLink = `/${Urls.APP}/${Urls.DETAILS}/${Urls.ORGANIZATION}/${this.parentOrganisation.uuid}/${this.languageCode}`;
    if (entityInfo.hasOwnProperty('badges')) {
      this.badges = entityInfo.badges;
    }
  }

  setOrganisationFields(entityInfo): void {
    this.entityType = ENTITY_TYPE.ORGANISATION;
    this.name = entityInfo.organisationName;
    this.uuid = entityInfo.organisationUuid;
    this.coverImageUrl = entityInfo.coverImageUrl;
    this.coverVideoUrl = entityInfo.organizationVideoUrl;
    this.cause = entityInfo.causeName;
    this.setCountries(entityInfo?.organisationCountriesNames);
    this.categories = entityInfo.organisationCategoriesNames;
    this.leader = entityInfo.organisationLeader;
    this.description = entityInfo.executiveSummary;
    this.allocation = entityInfo.percent;
    this.detailsPageLink = `/${Urls.APP}/${Urls.DETAILS}/${Urls.ORGANIZATION}/${this.uuid}/${this.languageCode}`;
    this.projects = entityInfo.projects.map((project: any) => {
      return {
        uuid: project.uuid,
        name: project.name,
        detailsPageLink: `/${Urls.APP}/${Urls.DETAILS}/${Urls.PROJECT}/${project.uuid}/${this.languageCode}`,
      };
    });
    if (entityInfo.hasOwnProperty('badges')) {
      this.badges = entityInfo.badges;
    }
    this.entityLogoUrl = entityInfo.organisationLogoUrl;
    this.numberOfFunders = entityInfo.numberOfFunders;
    this.missionStatement =
      entityInfo.missionStatement?.length > 230 ? entityInfo.missionStatement.substring(0, 230) + '...' : entityInfo.missionStatement;
    this.organisationWebsite = entityInfo.organisationWebsite ?? '';
  }

  setPortfolioFields(entityInfo): void {
    this.entityType = ENTITY_TYPE.PORTFOLIO;
    this.name = entityInfo.name;
    this.uuid = entityInfo.uuid;
    this.coverImageUrl = entityInfo.urlCoverImage || entityInfo.urlThumbnail;
    this.coverVideoUrl = entityInfo.urlVideo;
    this.description = entityInfo.description;
    this.cause = entityInfo.cause;
    this.setCountries(entityInfo?.countries);
    this.categories = entityInfo.categories;
    this.numberOfProjects = entityInfo.numberOfProjects;
    this.numberOfOrganisations = entityInfo.numberOfOrganisations;
    this.type = entityInfo.portfolioType;
    this.detailsPageLink = `/${Urls.APP}/${Urls.DETAILS}/${Urls.PORTFOLIO}/${this.uuid}`;
    if (entityInfo.hasOwnProperty('badges')) {
      this.badges = entityInfo.badges;
    }
  }

  setCountries(allCountries: string[]): void {
    if (!allCountries?.length) {
      this.countries = null;
      return;
    }
    this.multipleCountries = allCountries.length > 4;
    allCountries = allCountries.map((country) => 'GENERAL.DROPDOWNS.COUNTRIES.' + country);
    this.countries = this.multipleCountries ? allCountries.slice(0, 4) : allCountries;
  }
}

export interface CatalogEntityBadge {
  image: Media;
  name: string;
  rank: number;
  uuid: string;
}

export class KeyValue<T = string> {
  key: string;
  value: T;

  constructor(key: string, value: T) {
    this.key = key;
    this.value = value;
  }
}

export enum CatalogEntityCardType {
  REGULAR,
  COMPACT,
}
