import { Injectable } from '@angular/core';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from '../services/api.service';
import { EntityType } from '../constants/CompanyOrFirm';
import { environment } from '../../../environments/environment';
import { EntityNameListRequest } from '../models/import-export-csv/ExportCsv';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private readonly resourceUrl: string = '/v1/admin';

  // prettier-ignore
  constructor(
    private apiService: ApiService
  ) {
  }

  getUsers(body: any) {
    return this.apiService.post(`${this.resourceUrl}/get-users`, body);
  }

  exportUsers(body: any) {
    return this.apiService.post(`${this.resourceUrl}/download-user-file`, body);
  }

  getPermissionItems() {
    return this.apiService.get(`${this.resourceUrl}/get-permission-items`);
  }

  changeUserPermission(body: any) {
    return this.apiService.post(`${this.resourceUrl}/change-permission`, body);
  }

  updateInvitationVetted(body) {
    return this.apiService.post(`${this.resourceUrl}/update-token`, body);
  }

  changeVetted(invited: boolean, body: any) {
    return invited ? this.updateInvitationVetted(body) : this.changeUserPermission(body);
  }

  restoreUser(uuid: string) {
    return this.apiService.put(`${this.resourceUrl}/unarchive-user/${uuid}`);
  }

  archiveUser(uuid: string) {
    return this.apiService.put(`${this.resourceUrl}/archive-user/${uuid}`);
  }

  deleteUser(uuid: string) {
    return this.apiService.delete(`${this.resourceUrl}/delete-user/${uuid}`);
  }

  getUploadImageUrl() {
    return `${environment.server}${this.resourceUrl}/upload-files-for-user`;
  }

  inviteUsers(body: any) {
    return this.apiService.post(`${this.resourceUrl}/invite-user`, body);
  }

  getEntities(entityOptions: EntityType, searchInput: string) {
    return this.apiService.get(`${this.resourceUrl}/entities/${entityOptions}`, { searchInput });
  }

  checkName(email: string) {
    return this.apiService.get(`${this.resourceUrl}/check-name`, { email });
  }

  searchLanguage(name: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/search-language/${name}`);
  }

  createUpdateLangauge(body): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/create-update-language`, body);
  }

  checkCurrentImportCsvStatus(): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/import/logs`);
  }

  importCsv(uuid: string, csvType: string): Observable<any> {
    const params = new HttpParams().set('fileType', csvType);
    return this.apiService.post(`${this.resourceUrl}/import/${uuid}`, {}, params);
  }

  importCsvLogs(): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/import/logs`, { size: 10, page: 0 });
  }

  getEntityNameList(name: string, body: EntityNameListRequest): Observable<any> {
    return this.apiService.post(`${this.resourceUrl}/entity-name-list/${name}`, body);
  }

  exportCsv(entityType: string, selectAll: boolean, uuids: string[]): Observable<HttpResponse<Blob>> {
    return this.apiService.post(
      `${this.resourceUrl}/export-to-file`,
      { entityType, selectAll, uuids },
      {},
      {},
      { responseType: 'blob', observe: 'response' }
    ) as Observable<HttpResponse<Blob>>;
  }

  downloadImportCsvLog(uuid: string, currentTime: number, timeZone: string): Observable<HttpResponse<Blob>> {
    return this.apiService.get(
      `${this.resourceUrl}/logs/download/${uuid}`,
      { currentTime, timeZone },
      {},
      { responseType: 'blob', observe: 'response' }
    ) as Observable<HttpResponse<Blob>>;
  }

  processBlobDownload(res: HttpResponse<Blob>) {
    const filename = res.headers.get('Content-Disposition').split('filename=')[1].split('"')[1];
    const url = window.URL.createObjectURL(res.body);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}
